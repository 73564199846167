import { useState } from 'react'
import clsx from "clsx";

export const uniq = () => {
  const now = new Date()
  return [
    now.getUTCFullYear(),
    String(now.getUTCMonth() + 1).padStart(2, '0'),
    String(now.getUTCDate()).padStart(2, '0'),
  ].join('')
}
export const DeviceRegister = () => {
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState({})
  const [formValues, setFormValues] = useState({ batch: uniq() })

  return (
    <div className="card">
      <h5 className="card-header">Register device</h5>
      <div className="card-body">
        <form onSubmit={handleCommit}>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Batch"
              value={formValues.batch}
              className={clsx('form-control', errors.batch && 'is-invalid')}
              onChange={e => handleChange('batch', e.target.value)}
            />
            <div className="invalid-feedback">{errors.batch}</div>
          </div>
          {success
            ? <button className="btn btn-success" disabled={true}>Done</button>
            : <button className="btn btn-primary">Submit</button>
          }
        </form>
      </div>
    </div>
  )

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }

  function handleCommit(e) {
    e.preventDefault()

    fetch(`${process.env.REACT_APP_API_BASE_URL}/iot.device.register`, {
      method: 'POST',
      body: new URLSearchParams(formValues),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(res => {
      return res.json()
    }).then(res => {

      if (res.ok) {
        setSuccess(true)
        setTimeout(() => {
          setFormValues(prev => ({ ...prev, batch: uniq() }))
          setErrors({})
          setSuccess(false)
        }, 750)
        return
      }

      setErrors(res.errors.reduce((acc, cur) => ({ ...acc, [cur.property]: cur.message }), {}))
    })
  }
}