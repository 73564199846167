import { useState } from 'react'
import clsx from 'clsx'

const initialValues = { device: '', duration: '' }
export const RecordCreate = () => {
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState({})
  const [formValues, setFormValues] = useState(initialValues)

  return (
    <div className="card">
      <h5 className="card-header">Create record</h5>
      <div className="card-body">
        <form onSubmit={handleCommit}>
          <div className="mb-3">
            <input
              type="number"
              placeholder="Device"
              value={formValues.device}
              onChange={e => handleChange('device', e.target.value)}
              className={clsx('form-control', errors.device && 'is-invalid')}
            />
            <div className="invalid-feedback">{errors.device}</div>
          </div>
          <div className="mb-3">
            <input
              type="number"
              placeholder="Duration (ms)"
              value={formValues.duration}
              onChange={e => handleChange('duration', e.target.value)}
              className={clsx('form-control', errors.duration && 'is-invalid')}
            />
            <div className="invalid-feedback">{errors.duration}</div>
          </div>
          {success
            ? <button className="btn btn-success" disabled={true}>Done</button>
            : <button className="btn btn-primary">Submit</button>
          }
        </form>
      </div>
    </div>
  )

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }

  function handleCommit(e) {
    e.preventDefault()

    fetch(`${process.env.REACT_APP_API_BASE_URL}/iot.record.create`, {
      method: 'POST',
      body: new URLSearchParams(formValues),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(res => {
      return res.json()
    }).then(res => {
      if (res.ok) {
        setSuccess(true)
        setTimeout(() => {
          setFormValues(initialValues)
          setErrors({})
          setSuccess(false)
        }, 750)
        return
      }

      setErrors(res.errors.reduce((acc, cur) => ({ ...acc, [cur.property]: cur.message }), {}))
    })
  }
}