import { RecordCreate } from './RecordCreate'
import { DeviceRegister } from './DeviceRegister'
function App() {
  return (
    <div className="container p-4">
      <div className="row">
        <div className="col">
          <DeviceRegister/>
        </div>
        <div className="col">
          <RecordCreate/>
        </div>
      </div>
    </div>
  )
}

export default App;
